

#layout {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    align-content: stretch;
    
}

.content-container {
    border: 1px #443C68 solid;
    margin-top: 10px;
    padding: 5px 5px 5px 5px;
    flex: 1 1 auto;
    flex-grow: 5;
    width: 100%;
}


#copyright {
    bottom: 0;
    margin: 1% 1% 1% 1%;
    border: 1px #443C68 solid;
    width: 100%;
    display: flex;
    justify-content: center;
}