.cube {
    position: relative;
    margin-left: 15%;
    width: calc(1px * var(--size));
    height: calc(1px * var(--size));
    transform-style: preserve-3d;
    animation: animate 4s linear infinite;
}

@keyframes animate {
    0% {
        transform: rotateX(-30deg) rotateY(0deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(-30deg) rotateY(360deg) rotateZ(0deg);
    }
}

.cube div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;

}



.cube div span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#18122B, #635985);
    transform: rotateY(calc(90deg * var(--i))) translateZ(calc(0.5px * var(--size)));
    /* 300 / 2 = 150*/
}

.top {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 300px;
    background: #18122B;
    transform: rotateX(90deg) translateZ(calc(0.5px * var(--size)));
}

.top::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc(1px * var(--size));
    height: calc(1px * var(--size));
    background: #443C68;
    transform: translateZ(calc(-1px * var(--size) - (var(--size) * 0.30px)));
    filter: blur(20px);
    box-shadow: 0 0 120px rgba(0, 255, 0, 0.2),
        0 0 200px rgba(255, 0, 255, 0.4),
        0 0 300px rgba(255, 0, 255, 0.6),
        0 0 400px rgba(255, 0, 255, 0.8),
        0 0 500px rgba(255, 0, 255, 1);
}