

#content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#headshot {
    align-self: center;
    border-radius: 50%;
}