#navbar {
    margin-top: 2%;
    display: flex;
    width: 100%;
    justify-content: center;
    height: fit-content;
    border-bottom: 1px solid #443C68;
    border-top: 1px solid #443C68;
    padding-bottom: 2%;
    padding-top: 0.5%;
}

#navbar ul {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

#navbar .link {
    display: flex;
    flex-direction: column;
}

#navbar h1 {
    transform-style: preserve-3d;
    animation: bounce 2s linear infinite;
}

@keyframes bounce {
    0% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(-30%);
    }


    100% {
        transform: translateY(0%);
    }

}