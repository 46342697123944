* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}



html, body {
  position: fixed;
  overflow: auto;
}

h1, h2, h3, h4, h5, h6, p {
  color: whitesmoke;
}

body {
  position: fixed;
  display: flex;
  align-items: flex-start;
  max-height: 100vh;
  height: 100%;
  background: #050505;
}


